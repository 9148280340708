
import 'react-multi-carousel/lib/styles.css';
import { Container, Row, Col } from "react-bootstrap";
import alkgif from "../assets/img/alcestide.gif";

export const Skills = () => {

  return (
         <section className="skill">
      <Container>

        <Row className="aligh-items-center">
          <Col  md={6} xl={6}>


              <div >
                <h2>Abilità</h2>
                  <p>Le mie <b>competenze</b> sono in <b>continuo perfezionamento </b>e coprono un ampio spettro di argomenti all'interno dell'industria <b>IT</b>. Ho una particolare attitudine per lo <b>sviluppo software</b>, dove mi dedico a creare soluzioni innovative e performanti. Conosco una varietà di linguaggi, fra cui <b>C</b>, <b>C++</b>, <b>JavaScript</b> e <b>Python</b>. Inoltre, ho acquisito solide competenze nella <b>gestione di infrastrutture</b> e server, assicurandomi che le risorse siano sempre ottimizzate e sicure.</p>

              </div>
                  </Col>
          <Col  md={6} xl={6}>

                <h2>Esperienza</h2>
                  <p>Ho accumulato <b>2 anni di esperienza nel settore</b> e nutro una <b>forte passione</b> per tutto ciò che riguarda la <b>tecnologia</b>. Questa passione si traduce in un costante desiderio di <b>apprendimento</b> e innovazione, che mi spinge a lavorare su progetti personali sia in ambito <b>open-source</b> che privato. Collaborare con la comunità open-source mi permette di  <b>condividere</b> idee, apprendere da altri sviluppatori e  <b>contribuire</b> a progetti significativi, mentre i miei progetti personali mi offrono l'opportunità di esplorare nuove tecnologie e  <b>affinare </b> le mie competenze.</p>
                <div >
                </div>
                    </Col>
        </Row>

<img src={alkgif} style={{borderRadius: '60%'}}></img>

      </Container>
      <Row></Row>
    </section>
  )
}
