import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/header-img.svg";
import { ArrowRightCircle } from 'react-bootstrap-icons';

import TrackVisibility from 'react-on-screen';
import testgif from "../assets/img/alcestide.gif";

export const Banner = () => {
  
return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <span className="tagline">Benvenuto/a!</span>
                <h1>{`Alcestide`} <span><span className="wrap"></span></span></h1>
                  <p>Mi chiamo <b>Angelo Panariti</b>, sono un <b>esperto informatico</b> abile nello <b>sviluppo software</b> e nella gestione e <b>ottimizzazione</b> di <b>infrastrutture</b> cloud ed on‑premise.</p>
                                    <p>
Oltre alla mia passione per l'<b>informatica</b>, coltivo un interesse profondo per la <b>scrittura</b>, la <b>fotografia</b> e la <b>musica</b>. Cerco di gestire un piccolo <b>blog</b>, dove pubblico alcune delle mie <b>poesie</b>, <b>riflessioni</b> ed <b>articoli</b> su ciò che mi passa per la testa. Questo <b>spazio creativo</b> non solo mi permette di esprimere le mie <b>emozioni</b> e <b>idee</b>, ma anche di condividere il mio punto di vista con gli altri. </p>

                  <p>Vieni a dare un'occhiata, se ti va :)</p>
                  <button onClick={() => window.location.href='http://blog.alcestide.com'}>Blog <ArrowRightCircle size={25} /></button>

              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={12} xl={12}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
